/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid } from 'theme-ui';
import SectionHeader from 'components/section-header';
import FeatureCard from 'components/feature-card.js';
import Management from 'assets/newicons/management.svg';
import Engineering from 'assets/newicons/engineering.svg';
import ComputerApplication from 'assets/newicons/computer.svg';
import Designing from 'assets/newicons/design.svg';
import MassCommunication from 'assets/newicons/masscom.svg';
import Medical from 'assets/newicons/medicine.svg';


const data = [
  {
    id: 1,
    imgSrc: Management,
    altText: 'Management',
    title: 'Management',
    text:
      'Business Administration, Sales & Marketing, Project Management, Shipping and Logistics Management ',
      
    
  },
  {
    id: 2,
    imgSrc: Engineering,
    altText: 'Engineering',
    title: 'Engineering',
    text:
      'Chemical Engineering, Industrial Engineering, Civil Engineering, Electrical/ Electronics Engg.',
  },
  {
    id: 3,
    imgSrc: ComputerApplication,
    altText: 'Computer Application',
    title: 'Computer Application',
    text:
      'Data Science, DevOps, Artificial Intelligence and Machine Learning, Information Technology',
  },
  {
    id: 4,
    imgSrc: Designing,
    altText: 'Designing',
    title: 'Designing ',
    text:
      'UI/UX Desing, Digital Media Design, Creative Design, Product Design, Interior Designing, Fashion Designing',
  },
  {
    id: 5,
    imgSrc: MassCommunication,
    altText: 'Mass-communication',
    title: 'Mass-communication',
    text:
      'Digital Film Making, Animation & VFX, Digital Cinematography, Journalism, Visual Editing, ',
  },
  {
    id: 6,
    imgSrc: Medical,
    altText: 'Medical',
    title: 'Medical & Nursing',
    text:
      'Psychology, Cosmetology, Dentistry, MBBS, MD, Medical Nursing, Surgical Nursing, Gerontology, Physiotherapy',
  },
  
];

export default function Feature() {
  return (
    <section sx={{ variant: 'section.feature' }}>
      <Container>
        <SectionHeader
          slogan="Trending Career Paths"
          title="Latest Industry Demanding Courses"
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCard
              key={item.id}
              src={item.imgSrc}
              alt={item.altText}
              title={item.title}
              text={item.text}
              
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    pt: [0, null, null, null, null, null, 2],
    px: [5, 6, 0, null, 7, 8, 7],
    gridGap: [
      '40px 0',
      null,
      '45px 30px',
      null,
      '60px 50px',
      '70px 50px',
      null,
      '80px 90px',
    ],
    gridTemplateColumns: ['repeat(1,1fr)', null, 'repeat(2,1fr)', 'repeat(2,1fr)'],
  },
};
