/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid } from 'theme-ui';
import SectionHeader from '../components/section-header';
import FeatureCardColumn from 'components/feature-card-column.js';
import Courses from 'assets/newicons/courses.svg';
import Universities from 'assets/newicons/university.svg';
import Mentorship from 'assets/newicons/mentor.svg';
import PostLanding from 'assets/newicons/postlanding.svg';

const data = [
  {
    id: 1,
    imgSrc: Courses,
    altText: '10k + Courses',
    title: '10k + Courses',
    text:
      'Industry demanding graduate and post-graduate courses from top universities.',
  },
  {
    id: 2,
    imgSrc: Universities,
    altText: '1000 + Universities',
    title: '1000 + Universities',
    text:
      'Top universities in USA, United Kingdom, Canada and Australia.',
  },
  {
    id: 3,
    imgSrc: Mentorship,
    altText: 'Personalised Mentorship',
    title: 'Personalised Mentorship',
    text:
      'Our experienced councellors are here to get your doubts cleared and set your career path.',
  },
  {
    id: 4,
    imgSrc: PostLanding,
    altText: 'Post Landing Support',
    title: 'Post Landing Support',
    text:
      'Get local support for part-time jobs, accomodation after you land at your destination country. ',
  },
];

export default function KeyFeature() {
  return (
    <section sx={{ variant: 'section.keyFeature' }} id="feature">
      <Container>
        <SectionHeader
          slogan="Why Choose us ?"
          title="Learn from best universities abroad."
        />
        

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCardColumn
              key={item.id}
              src={item.imgSrc}
              alt={item.altText}
              title={item.title}
              text={item.text}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    width: ['100%', '80%', '100%'],
    mx: 'auto',
    gridGap: [
      '35px 0',
      null,
      '40px 40px',
      '50px 60px',
      '30px',
      '50px 40px',
      '55px 90px',
    ],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(4,1fr)',
    ],
  },
};
